import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { DataminingService } from 'src/app/services/datamining.service';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-mobile-nav-menu',
  templateUrl: './mobile-nav-menu.component.html',
  styleUrls: ['./mobile-nav-menu.component.css']
})
export class MobileNavMenuComponent implements OnInit {

  currentPage: string = '';
  pagesNone = [
    '/chat',
    '/agent-area'
  ];
  isPropertyPage: boolean = false;
  buttonOpen: boolean = false;
  buttonOpenAgent: boolean = false;

  constructor(
    private router: Router,
    private eventService: EventService,
    private dataminingService: DataminingService,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {
    let filter = this.pagesNone.filter(page => {
      return this.router.url.split('?')[0].indexOf(page) > -1;
    });
    if (filter.length === 0) {
      if (this.router.url.split('?').length > 1 && this.router.url.split('?')[1].indexOf('latlng') > -1) {
        this.currentPage = '/nearby';
      } else {
        this.currentPage = this.router.url.split('?')[0]
      }
    } else {
      this.currentPage = '';
    }
    // this.currentPage = filter.length > 0 ? '' : this.router.url.split('?')[0];
    if (this.currentPage.indexOf('property/') > -1 || this.currentPage.indexOf('/enterprises/') > -1) {
      this.isPropertyPage = true;
    } else if (this.currentPage.indexOf('agents/') > -1) {
      this.currentPage = '/agent';
      this.isPropertyPage = false;
      this.buttonOpen = false;
    } else {
      this.isPropertyPage = false;
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        let filter = this.pagesNone.filter(page => {
          return event.urlAfterRedirects.split('?')[0].indexOf(page) > -1;
        });
        if (filter.length === 0) {
          if (this.router.url.split('?').length > 1 && this.router.url.split('?')[1].indexOf('latlng') > -1) {
            this.currentPage = '/nearby';
          } else {
            this.currentPage = this.router.url.split('?')[0]
          }
        } else {
          this.currentPage = '';
        }
        // this.currentPage = filter.length > 0 ? '' : this.router.url.split('?')[0];
        if (this.currentPage.indexOf('property/') > -1 || this.currentPage.indexOf('/enterprises/') > -1) {
          this.isPropertyPage = true;
        } else if (this.currentPage.indexOf('agents/') > -1) {
          this.currentPage = '/agent';
          this.isPropertyPage = false;
          this.buttonOpen = false;
        } else {
          this.isPropertyPage = false;
          this.buttonOpen = false;
          this.buttonOpenAgent = false;
        }
      }
    });
    // this.watchPosition();
  }

  clickButton() {
    this.buttonOpen = !this.buttonOpen;
    this.eventService.broadcast('toggle-property-float');
  }

  clickButtonAgent() {
    this.buttonOpenAgent = !this.buttonOpenAgent;
    this.eventService.broadcast('toggle-agent-float');
  }

  getLocation() {
    let latlng = localStorage.getItem('latlng');
    if (latlng) {
      this.router.navigate(['/properties'], { queryParams: { latlng: true } });
    } else {
      navigator.geolocation.getCurrentPosition(position => {
        this.watchPosition();
        const newLatLng = { lat: position.coords.latitude, lng: position.coords.longitude };
        localStorage.setItem('latlng', JSON.stringify(newLatLng));
        this.router.navigate(['/properties'], { queryParams: { latlng: true } });
      }, () => {
        this.router.navigate(['/properties']);
      }, { timeout: 10000 });
    }
    // this.router.navigate(['/properties'], { queryParams: { latlng: true } });

  }

  watchPosition() {
    const interval = setInterval(() => {
      if (this.cookieService.check('cookieConsent')) {
        navigator.geolocation.watchPosition(position => {
          const newLatLng = { lat: position.coords.latitude, lng: position.coords.longitude };
          localStorage.setItem('latlng', JSON.stringify(newLatLng));
          this.eventService.broadcast('new-location');
        }, () => localStorage.removeItem('latlng'));
        clearInterval(interval);
      };
    });
  }

}
